<template>
<view class="font-serif">
  <Header style="width: 100%;"/>
  <!-- 筛选和搜索 -->
  <div class="filterSearch">
    <!-- 筛选-->
    <div class="filterBox custom-style">
      <el-segmented v-model="status" :options="statusOptionsEs" @change="handleChange" v-if="language === 0"></el-segmented>
      <el-segmented v-model="status" :options="statusOptionsCN" @change="handleChange" v-else></el-segmented>
    </div>
    <!-- 搜索 -->
    <div style="display: flex; justify-content: center; align-items: center;margin-bottom: 20px; ">
      {{ language ===0 ? 'Código': '产品编号'}}
      <el-input v-model="searchTxt" style="width: 200px;margin-left: 10px;" clearable
        :placeholder="language ===0 ? 'buscar por código...': '按照产品编号搜索'" ></el-input>
      <el-button style="margin-left: 10px; background-color:#dc2626;color: white;" @click="handleSearch">{{ language ===0 ? 'Buscar': '搜索' }}</el-button>
    </div>
  </div>
  <el-empty v-if="info.results.length === 0 "/>
  <div class="detail_container" v-else>
    <div class="detail">
      <el-card style="margin-bottom: 30px; position: relative;" v-for="item in info.results" :key="item.id">
        <div>
          <el-image style="width: 100%; height: 300px;border-radius: 10px;" :src="item.image"></el-image>
          <div class="info_box">
            <div class="title">{{ language ===0 ?item.name :item.cn}}</div>
            <el-divider content-position="left">{{ language ===0 ? 'Informaciones': '产品信息：'}}</el-divider>
            <el-row :gutter="40" >
              <el-col :span="8">
                <div style="font-size: 18px; ">
                    <div class="text-gray-500" >{{ language ===0 ? 'Ancho': '宽'}}</div>
                    <div style="font-weight: bold;" class="font-sans">{{ item.ancho }}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div style="font-size: 18px;">
                    <div class="text-gray-500">{{ language ===0 ? 'Largo': '长'}}</div>
                    <div style="font-weight: bold;" class="font-sans">{{ item.largo }}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div style="font-size: 18px;">
                  <div class="text-gray-500">{{ language ===0 ? 'Altura': '高 '}}</div>
                  <div style="font-weight: bold;" class="font-sans">{{ item.altura }}</div>
                </div>
              </el-col>
            </el-row>
            <div style="display: flex;margin-top: 20px; align-items: center; ">
                <div class="text-gray-500" style="margin-right: 10px;">{{ language ===0 ? 'Código:': '产品编号：'}}</div>
                <div class="model font-sans text-xl">{{ item.code }}</div>
            </div>
            <div style="display: flex; margin-right: 30px;font-size: 18px;margin-top: 10px;">
              <div class="text-gray-500" style="margin-right: 10px;">{{ language ===0 ? 'Material:': '产品材料：'}}</div>
              <div style="font-weight: bold;" class="font-sans">{{ item.material }}</div>
            </div>
            <div style="display: flex; margin-right: 30px;font-size: 18px;margin-top: 10px;">
              <div class="text-gray-500" style="margin-right: 10px;">{{ language ===0 ? 'Color:': '产品颜色：'}}</div>
              <div style="font-weight: bold;" class="font-sans">{{ item.color }}</div>
            </div>
            <div style="display: flex; margin-right: 30px;font-size: 18px;margin-top: 10px;">
                <div class="text-gray-500" style="margin-right: 10px;">{{ language ===0 ? 'Cantidad por Caja:': '每箱数量：'}}</div>
                <div style="font-weight: bold;" class="font-sans">{{ item.quantity }}</div>
            </div>
          </div>
        </div>
        <!-- 即将上线 -->
        <div v-if="item.status===3" class="available_box">
          {{ language ===0 ? 'Próximamente': '即将到货'}}
        </div>
      </el-card>
    </div>
  </div>
  <!-- 分页 -->
  <!-- <Page :info="info"/> -->
  <div style="display: flex; justify-content: center; margin-bottom: 50px;" class="font-sans">
    <el-pagination 
      background :current-page="current" @current-change="goToPage"
      :hide-on-single-page="true"
      :pager-count="11"
      :page-size="12"
      :total="info.count"
      layout="prev, pager, next"
    />
  </div>
  <div style="height: 50px;"></div>
  <Footer style="width: 100%; position: fixed; bottom: -10px;" />
</view>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
// import Detail from '@/components/Detail.vue';
// import Page from '@/components/Page.vue';
import axios from 'axios';
export default {
    name:'ProductDetail',
    components: {
        Header,
        Footer,
        // Page
        // Detail
    },
    data(){
      return{
        info:{results:[]},
        current:1,
        language:0,
        status:0,
        statusOptionsEs:[
          {label:'Todos',value:0},
          {label:'Nuevos',value:2},
          {label:'Próximamente',value:3}
        ],
        statusOptionsCN:[
          {label:'全部',value:0},
          {label:'最新到货',value:2},
          {label:'即将到货',value:3}
        ],
        searchTxt:''
      }
    },
    mounted(){
      this.get_product_data()
      this.current=this.getPageFromUrl()
    },
    methods:{
      get_product_data(){
        let url = '/api/web'
        // 获取路由的配置参数
        // 1. 获取页面参数
        const page =Number(this.$route.query.page);
        // 2. 获取类型参数
        const category_id = this.$route.query.category_id
        // 3. 获取语言参数
        this.language = Number(this.$route.query.language)
        if(!this.language){
          this.language = 0
        }
        // 4. 获取筛选参数
        const status = Number(this.$route.query.status)
        if(!status){
          this.status = 0
        }
        // 5. 获取搜索参数
        const code = this.$route.query.code
        


        const params = new URLSearchParams
        if(page){
          params.append('page',page)
        }
        else{
          this.current = 1
        }
        if (category_id){
          params.append('category_id',category_id)
        }
        if (status){
          params.append('status',status)
          this.status = status
        }
        if(code){
          params.append('code',code)
          this.searchTxt = code
        }

        

        url = url +"?"+params.toString()
        // 判断是否是第一页或者没有page参数
        // if (!isNaN(page) && (page !== 0)){
        //   url = url +'/?page='+page;
        // }
        // axios发送请求
        axios.get(url)
          .then(response=>{
            this.info = response.data
            window.scrollTo(0, 0);
          })
          .catch(error =>{console.log(error)})
      },
      // 跳转页面
      goToPage(page){
        this.current = page
        // 获取当前参数
        const query = {...this.$route.query};
        // 更新page参数
        query.page = page;
        // 跳转并传参数对象
        this.$router.push({
          query
        })
      },
      //获取当前页面
      getPageFromUrl(){
          const page = Number(this.$route.query.page)
          // 如果page不存在就赋值为1
          return page ? page :1
      },
      handleChange(value){
        const index = this.$route.query.category_id
        const code = this.$route.query.code
        if (value === 0){
          this.$router.push({
            query:{language:this.language,category_id:index,code:code}
          })
        }
        else{
          this.$router.push({
            query:{language:this.language,category_id:index,status:value,code:code}
          })
        }
        
      },
      handleSearch(){
        if(this.searchTxt){
          this.$router.push({
            query:{language:this.language,code:this.searchTxt}
          })
        }
        else{
          this.$router.push({
            query:{language:this.language}
          })
        }
      }
    },
    watch:{
      //监听路由的变化
      $route(){
        this.get_product_data()
      }
    }
}
</script>

<style lang="scss" scoped>
.filterSearch{
  padding: 20px 5%;
  display: flex; 
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
  padding-bottom: 0;
  .filterBox{
    margin-bottom: 20px;
    font-weight: bolder;
  }
  .custom-style .el-segmented {
    --el-segmented-item-selected-color: white;
    --el-segmented-item-selected-bg-color: #dc2626;
    --el-border-radius-base: 16px;
  }
}
.detail_container{
  padding: 20px 5%;
  width: 100%;
  margin: auto;
  padding-top:0 ;
  .detail{
    width: 100%;
    text-align: center;
    display: grid; 
    justify-content: space-between;
     /*  声明列的宽度  */
    grid-template-columns: repeat(auto-fill, 370px);
     /*  声明行间距和列间距  */
    grid-gap: 10px;
    .info_box{
        width: 100%;
        .title{
            padding: 5px 10px;
            font-weight: bolder;
            font-size: 27px;
            text-align: left;
        }
        .model{
            color: white;
            background-color: #25a766;
            border-radius: 5px;
            margin-left: 10px;
            padding: 3px 10px;
        }
    }
    .available_box{
      color: #dc2626;
      font-size: 20x;
      font-weight: bold;
      border-radius: 5px;
      border: #dc2626 2px solid;
      display: inline-block;
      padding: 5px 10px;
      background-color: white;
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}
</style>

<style>
.el-pagination.is-background .el-pager li.is-active {
  background-color: #dc2626;
}

</style>