import { createApp } from 'vue'
import App from './App.vue' // 导入根组件
import router from './router' // 导入路由
import store from './store' // 导入状态管理仓库
import './assets/css/tailwind.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// 创建实例应用
createApp(App).use(store).use(router).use(ElementPlus).mount('#app')

// // 全局注册icon
// const app = createApp(App);
// for (const [key,component] of Object.entries(ElementPlusIconsVue)){
//     app.component(key,component);
// }

// app.use(store).use(router).use(ElementPlus).mount('#app')