<template>
  <view>
    <div class="box">
      <!-- <div style="display: flex;align-content: center;">
        <div class="icon-[mdi--customer-service] icon_box" style="margin-right: 10px;"></div>
        <div style="font-size: 20px;color: white; font-weight: bold; line-height: 40px;" class="font-sans">
          +(506) 8456-7880
        </div>
      </div> -->
      <div style="line-height: 40px; color: white;">
        © 2024 AIBO Plastic Products Co., Ltd., All Rights Reserved
      </div>
      <!-- <div>
        <div class="icon-[ic--baseline-facebook] icon_box"></div>
      </div> -->
    </div>
  </view>
</template>

<script>
export default {
    name:'Footer' // 该组件的名字
}
</script>

<style lang="scss" scoped>
.box{
  background-color: #25a766; 
  width: 100%; 
  height: 50px;
  padding: 5px 20px;
  display: flex;
  align-content: center;
  // justify-content: space-between;
  justify-content: center;
  .icon_box{
    width: 40px; height: 40px; color: white;
  }
}
</style>